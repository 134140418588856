.value-with-labels {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* gap: 20px; */
}
.icon-label-flex {
	display: flex;
	justify-content: start;
	gap: 20px;
}
.edit-button-container {
	display: flex;
	justify-content: right;
	align-items: right;
	gap: 15px;
	margin-top: 15px;
}
