/* .css-lqq3n7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    background-color: red;
} */
.MuiPagination-root {
	button {
		color: rgb(16, 84, 152) !important;
		border: 1px solid rgb(16, 84, 152) !important;
		font-weight: 500;
		/* background-color: rgb(240, 251, 242) !important; */
	}
}

/* .MuiPagination-root.Mui-selected {
           button:active {
            background-color: rgb(250, 179, 37) !important;
           }
           
            
  } */
