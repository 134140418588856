.client-brand-logo-container {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-brand-logo-container img {
  max-width: 80%;
}
.brand-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
