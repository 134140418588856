@use "../../../../globalStyles/scss/base/typography" as typography;
@use "../../../../globalStyles/scss/layout/authLayout" as auth;
@use "../../../../globalStyles/scss/base/font-face" as fonts;
@use "../../../../globalStyles/scss/base/responsive-breakpoints" as breakpoint;
@import "../../../../globalStyles/scss/partials/colors";

* {
	// margin: 0;
	padding: 0;
	box-sizing: 0;
}

.forget-username-main-container .forget-username-logo-container {
	margin-bottom: 1rem;
}

.forget-username-main-container .forget-username-newleey-logo {
	margin-top: 1rem;
	// padding: 10px 20px; /* Adds padding inside the text box */
	// max-width: 90%; /* Sets the width of the image */
	border-radius: 7px; /* Rounds the corners of the text box */
	// box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
	// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	// margin-bottom: -0.9rem;
}

.forget-username-main-container {
	@include auth.mainPageLayout;

	.left-section {
		@include auth.leftPageSection;

		.illustration {
			width: 50%;
		}

		.tagline {
			@include auth.leftTaglineLayout;

			h3 {
				@include typography.authTaglineMainText;
				font-size: 50px;
				margin: 0;
				margin-top: 1.5rem;
			}

			span {
				@include typography.authTaglineSubtext;
			}
		}
	}

	.right-section {
		@include auth.rightSection;

		.nexcaliber-logo {
			width: 40%;
		}

		h3 {
			@include auth.rightSectionMessage;
			margin: 5px;

			span {
				font-weight: 400;
			}
		}

		.subheading {
			font-size: 15px;
			margin: 0;
		}

		.form-container {
			@include auth.authFormLayout;
			margin-left: 0;

			.select-role-container {
				text-align: center;
				margin-top: 40px;

				.role-select-button {
					background-color: #cfcfcf;
					width: 200px;
				}

				.expand-icon {
					color: #8c68e1;
				}
			}

			.form-field-input {
				width: 400px;
				margin-top: 40px;
				border-radius: 5px;

				.adornment-color {
					color: #8c68e1;
				}
			}
		}
		.sign-in-container {
			@include auth.signInHelpLinkContainer;
			margin-top: 50px !important;

			.link-container {
				.sign-in-link {
					color: map-get(map-get($colors, "auth"), "secondary-1");
					@include typography.supportiveFooterLinks;
				}
				.sign-in-link:hover {
					text-decoration: underline !important;
				}
			}
		}

		.forget-username-button-container {
			display: flex;
			justify-content: center;
			margin-top: 40px;
			width: 100%;

			.forget-username-button {
				background-color: #8c68e1 !important;
				color: "#ffff" !important;
				padding: 10px !important;
				width: 250px !important;
				border-radius: 50px !important;

				.button-label-with-icon {
					color: white;
				}

				.button-icon {
					color: #ffff;
					margin-left: 15px;
				}
			}
		}
		.copyright-text-container {
			@include typography.copywriteText;
		}
	}

	// RESPONSIVE CODE
	@media (max-width: breakpoint.$xs),
		(max-width: breakpoint.$sm),
		(max-width: breakpoint.$md),
		(max-width: breakpoint.$md-2) {
		@include auth.mainPageLayout;
		.left-section {
			display: none;
		}

		.right-section {
			@include auth.rightSection;
			width: 100%;
			height: 100vh;

			.nexcaliber-logo {
				margin-top: 10px !important;
				width: 60%;
			}

			h3 {
				@include auth.rightSectionMessage;
				font-size: 32px !important;

				span {
					font-weight: 400;
				}
			}

			.subheading {
				font-size: 14.5px;
			}

			.form-container {
				@include auth.authFormLayout;

				.select-role-container {
					text-align: center;
					margin-top: 40px !important;

					.role-select-button {
						background-color: #cfcfcf;
						width: 200px;
					}

					.expand-icon {
						color: #8c68e1;
					}
				}

				.form-field-input {
					margin-top: 40px !important;
					border-radius: 5px;
					width: 350px;

					.adornment-color {
						color: #8c68e1;
					}
				}
			}
			.sign-in-container {
				@include auth.signInHelpLinkContainer;
				margin-top: 50px;

				.link-container {
					.sign-in-link {
						color: map-get(map-get($colors, "auth"), "secondary-1");
						@include typography.supportiveFooterLinks;
					}
					.sign-in-link:hover {
						text-decoration: underline !important;
					}
				}
			}

			.forget-username-button-container {
				display: flex;
				justify-content: center;
				margin-top: 40px;
				width: 80%;

				.forget-username-button {
					background-color: #8c68e1 !important;
					color: "#ffff" !important;
					padding: 10px !important;
					width: 250px !important;
					border-radius: 50px !important;

					.button-label-with-icon {
						color: white;
					}

					.button-icon {
						color: #ffff;
						margin-left: 15px;
					}
				}
			}
		}
	}

	@media (max-width: breakpoint.$ipad_air),
		(max-width: breakpoint.$ipad_air_next_gen),
		(max-width: breakpoint.$ipad_mini),
		(max-width: breakpoint.$ipad_mini_next_gen) {
		.right-section {
			.nexcaliber-logo {
				width: 260px;
			}
		}
	}
}
