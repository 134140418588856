@use "./font-face" as fonts;
@import "../partials/colors";

@mixin authTaglineMainText {
	font-family: fonts.$auth-font-secondary !important;
	color: white;
	font-size: 70px;
	opacity: 70%;
}

@mixin authTaglineSubtext {
	font-family: fonts.$auth-font-secondary !important;
	color: white;
	font-size: 30px;
	opacity: 80%;
}

@mixin supportiveFooterLinks {
	text-decoration: none !important;
	font-weight: 600;
}

@mixin copywriteText {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	color: map-get(map-get($colors, "auth"), "secondary-2");
	font-family: fonts.$auth-font-primary !important;
	bottom: 10px;
}
