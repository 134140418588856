.card-top-section {
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 1rem; */
}
.card-top-section .card-icon {
	background-color: #3caa8c;
	border-radius: 0.3rem;
	padding: 0.15rem 0.5rem;
}
.card-bottom-section {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	/* margin-top: 1rem; */
}
.card-bottom-section .card-value {
	font-size: 20px;
	margin-bottom: 0;
	margin-top: 0;
}
.card-bottom-section .card-growth-percent {
	background-color: #ffaeae;
	border-radius: 0.5rem;
	padding: 0.2rem 0.5rem;
	color: red;
}

.card-box {
	/* background-color: blue !important; */
	padding: 15px 15px;
}
.card-title {
	font-size: 16px;
	font-weight: 600;
	color: rgb(20, 20, 20);
}
.card-value {
	font-size: 25px !important;
	font-weight: 500 !important;
	color: #0a6099 !important;
	font-family: "poppins", sans-serif !important;
}
.cards {
	width: 200px;
	background: linear-gradient(60deg, rgb(248, 248, 248), rgb(189, 224, 224));

	transition: box-shadow 0.3s ease;
	outline: none;
	cursor: pointer;
}
.cards:hover {
	/* width: 210px;
  height: 100px;
  transition: all 1s ease-in; */
	box-shadow: 0 4px 10px 4px rgba(3, 87, 110, 0.3);
}
