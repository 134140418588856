/* Form styles for announcement + create member */

.pf-grid-container {
	display: flex;
	flex-direction: column;
}
.grid {
	width: 100%;
}

.pf-action-button-container {
	text-align: end;
	margin-bottom: 1rem;
}

.margin-top-action-button {
	margin-top: 2rem;
}

.pf-title {
	margin-top: 2rem;
	/* margin-bottom: 1rem; */
	font-size: 1.2rem;
	font-weight: bold;
	text-align: left;
}

.pf-paper-container {
	margin-top: 1rem;
	padding: 2rem 2rem 0rem 2rem;
	/* width: 100%; */
}

.pf-div-container {
	width: 100%;
}

.pf-label-text {
	text-align: left;
	font-size: 1rem;
	color: #424242;
	font-weight: bold;
	margin-top: 0.7em;
	/* margin-bottom: 1em; */
}

.pf-label {
	margin-bottom: 2rem !important;
}

.check-support {
	margin-left: -1.5rem;
}

@media (max-width: 1280px) {
	/* .smooth-scroll-nav {
		display: none;
	} */
	.page-design-container {
		display: flex;
		flex-direction: column-reverse;
	}
	.vertical-navigation {
		display: flex !important;
		flex-direction: row !important;
		justify-content: flex-end !important;
	}
}
