@use "../../../../globalStyles/scss/base/responsive-breakpoints" as breakpoint;
@use "../../../../globalStyles/scss/base/typography" as typography;
@use "../../../../globalStyles/scss/layout/authLayout" as auth;
@use "../../../../globalStyles/scss/base/font-face" as fonts;
@import "../../../../globalStyles/scss/partials/colors";

* {
	// margin: 0;
	padding: 0;
}
.login-parent-container {
	margin: 0 !important;
	padding: 0 !important;
	background-color: #8c68e1 !important;
}

.login-main-container .login-auth-logo-container {
	margin-bottom: 3rem;
}

.login-main-container .newleey-logo {
	// padding: 10px 20px; /* Adds padding inside the text box */
	// max-width: 90%; /* Sets the width of the image */
	border-radius: 7px; /* Rounds the corners of the text box */
	// box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
	// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	// margin-bottom: -0.9rem;
}

.login-main-container {
	@include auth.mainPageLayout;
	margin: 0 !important;

	// LEFT SECTION OF THE PAGE
	.left-section {
		@include auth.leftPageSection;

		.login-page-illustration {
			width: 40%;
		}

		.tagline {
			@include auth.leftTaglineLayout;

			h3 {
				@include typography.authTaglineMainText;
				font-size: 50px;
				margin: 0;
				margin-top: 1.5rem;
			}

			span {
				@include typography.authTaglineSubtext;
			}
		}
	}
	.welcome-text {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	// RIGHT SECTION OF THE PAGE
	.right-section {
		@include auth.rightSection;
		margin: 0;

		// third party agent logo
		.newleey-logo {
			// margin-top: 18px;
			width: 250px;
		}

		.subheader {
			font-size: 13px;
			margin-bottom: 5px;
		}

		h3 {
			@include auth.rightSectionMessage;
			margin: 0;
		}

		// FORM
		.form-container {
			@include auth.authFormLayout;
			margin-left: 0 !important;

			.select-role-container {
				text-align: center;
				margin-top: 20px;

				.role-select-button {
					background-color: #cfcfcf;
					width: 200px;
				}

				.expand-icon {
					color: #8c68e1;
				}

				.validation-text {
					align-items: center;
				}
			}

			.form-field-input {
				margin-top: 20px !important;
				width: 350px !important;
				border-radius: 5px !important;

				.adornment-color {
					color: #8c68e1;
				}
			}

			// not yet registered?
			.sign-up-link-container {
				margin-top: 20px;
				font-family: fonts.$auth-font-primary !important;

				.link-container {
					.sign-up-link {
						color: map-get(map-get($colors, "auth"), "secondary-1");
						@include typography.supportiveFooterLinks;
					}
					.sign-up-link:hover {
						text-decoration: underline !important;
					}
				}
			}

			.forgot-credentials-link-container {
				@include auth.supportiveFooterLinksLayout;
				margin-top: 20px !important;

				.link-container {
					.forgot-username-link {
						@include typography.supportiveFooterLinks;
						color: map-get(map-get($colors, "auth"), "secondary-1");
						font-size: 15px;
					}
					.forgot-username-link:hover {
						text-decoration: underline !important;
					}
					.forgot-password-link {
						@include typography.supportiveFooterLinks;
						color: map-get(map-get($colors, "auth"), "secondary-1");
						font-size: 15px;
					}
					.forgot-password-link:hover {
						text-decoration: underline !important;
					}
				}
			}
			TextField {
				width: 100%;
			}
		}
		.copyright-text {
			font-size: 12px;
		}
		.help-text {
			font-size: 15px;
		}

		.login-button-container {
			margin-top: 30px;
			width: 100%;
			display: flex;
			justify-content: center;

			.login-button {
				background-color: #8c68e1;
				color: "#ffff";
				padding: 10px;
				width: 250px;
				border-radius: 50px;
			}
		}

		.login-page-copyright-text-container {
			@include typography.copywriteText;
		}
	}

	// RESPONSIVE CODE
	@media (max-width: breakpoint.$xs),
		(max-width: breakpoint.$sm),
		(max-width: breakpoint.$md),
		(max-width: breakpoint.$md-2) {
		.left-section {
			display: none;
		}

		.right-section {
			width: 100%;
			height: 100vh;

			.newleey-logo {
				margin-top: 10px !important;
				width: 250px;
			}

			.subheader {
				font-size: 11px;
			}

			h3 {
				@include auth.rightSectionMessage;
				font-size: 35px;
			}

			// FORM
			.form-container {
				@include auth.authFormLayout;
				margin-left: 0 !important;

				.select-role-container {
					text-align: center;
					margin-top: 20px;
					margin-bottom: 20px;

					.role-select-button {
						background-color: #cfcfcf;
						width: 200px;
					}

					.expand-icon {
						color: #8c68e1;
					}

					.validation-text {
						align-items: center;
					}
				}

				.form-field-input {
					margin-top: 20px !important;
					width: 350px;
					border-radius: 5px;

					.adornment-color {
						color: #8c68e1;
					}
				}

				// not yet registered?
				.sign-up-link-container {
					margin-top: 20px;
					font-family: fonts.$auth-font-primary !important;
					font-size: 15px;

					.link-container {
						.sign-up-link {
							color: map-get(map-get($colors, "auth"), "secondary-1");
							@include typography.supportiveFooterLinks;
						}
						.sign-up-link:hover {
							text-decoration: underline !important;
						}
					}
				}

				.forgot-credentials-link-container {
					@include auth.supportiveFooterLinksLayout;
					margin-top: 40px !important;
					font-size: 15px;

					.link-container {
						.forgot-username-link {
							@include typography.supportiveFooterLinks;
							color: map-get(map-get($colors, "auth"), "secondary-1");
						}
						.forgot-username-link:hover {
							text-decoration: underline !important;
						}
						.forgot-password-link {
							@include typography.supportiveFooterLinks;
							color: map-get(map-get($colors, "auth"), "secondary-1");
						}
						.forgot-password-link:hover {
							text-decoration: underline !important;
						}
					}
				}
				TextField {
					width: 100%;
				}
			}

			.login-button-container {
				margin-top: 20px;
				width: 100%;
				display: flex;
				justify-content: center;

				.login-button {
					background-color: #8c68e1 !important;
					color: "#ffff" !important;
					padding: 10px !important;
					width: 250px !important;
					border-radius: 50px !important;
				}
			}

			.login-page-copyright-text-container {
				@include typography.copywriteText;
				font-size: 12px !important;

				span {
					font-size: 10px;
					margin-top: 30px !important;
				}
			}
		}
	}

	// RESPONSIVE CODE FOR IPAD

	@media (max-width: breakpoint.$ipad_air),
		(max-width: breakpoint.$ipad_air_next_gen),
		(max-width: breakpoint.$ipad_mini),
		(max-width: breakpoint.$ipad_mini_next_gen) {
		.right-section {
			.newleey-logo {
				margin-top: 10px !important;
				width: 260px;
			}
		}
	}
}
