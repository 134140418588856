@use "../../../../../globalStyles/scss/base/typography" as typography;
@use "../../../../../globalStyles/scss/layout/authLayout" as auth;
@use "../../../../../globalStyles/scss/base/font-face" as fonts;
@use "../../../../../globalStyles/scss/base/responsive-breakpoints" as breakpoint;
@import "../../../../../globalStyles/scss/partials/colors";

.reset-password-main-container .reset-password-logo-container {
	margin-bottom: 1rem;
}

.reset-password-main-container .reset-password-newleey-logo {
	margin-top: 1rem;
	// padding: 10px 20px; /* Adds padding inside the text box */
	// max-width: 90%; /* Sets the width of the image */
	border-radius: 7px; /* Rounds the corners of the text box */
	// box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
	// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	// margin-bottom: -0.9rem;
}

.reset-password-main-container {
	@include auth.mainPageLayout;

	.left-section {
		@include auth.leftPageSection;

		.illustration {
			width: 60%;
		}

		.tagline {
			@include auth.leftTaglineLayout;

			h3 {
				@include typography.authTaglineMainText;
				margin: 0;
				margin-top: 1.5rem;
			}

			span {
				@include typography.authTaglineSubtext;
			}
		}
	}

	.right-section {
		@include auth.rightSection;

		.nexcaliber-logo {
			width: 40%;
		}

		h3 {
			@include auth.rightSectionMessage;

			span {
				font-weight: 400;
				margin: 0;
			}
		}

		.subheading {
			font-size: 15px;
			margin: 0;
		}

		.form-container {
			@include auth.authFormLayout;
			margin-left: 0;

			.form-field-input {
				width: 85%;
				margin-top: 40px;
				border-radius: 5px;

				.adornment-color {
					color: #8c68e1;
				}
			}

			.confirm-password-field {
				margin-top: 30px !important;
			}
		}

		.sign-up-link-container {
			@include auth.signInHelpLinkContainer;
			margin-top: 30px !important;

			.link-container {
				.sign-up-link {
					color: map-get(map-get($colors, "auth"), "secondary-1");
					@include typography.supportiveFooterLinks;
				}
				.sign-up-link:hover {
					text-decoration: underline !important;
				}
			}
		}

		.sign-in-container {
			@include auth.signInHelpLinkContainer;
			margin-top: 40px !important;

			.link-container {
				.sign-in-link {
					color: map-get(map-get($colors, "auth"), "secondary-1");
					@include typography.supportiveFooterLinks;
				}
				.sign-in-link:hover {
					text-decoration: underline !important;
				}
			}
		}

		.reset-password-button-container {
			display: flex;
			justify-content: center;
			margin-top: 40px;
			width: 100%;

			.reset-password-button {
				background-color: #8c68e1;
				color: "#ffff";
				padding: 10px;
				width: 250px;
				border-radius: 50px;

				.button-label-with-icon {
					color: white;
				}

				.button-icon {
					color: #ffff;
					margin-left: 15px;
				}
			}
		}

		.copyright-text-container {
			@include typography.copywriteText;
		}
	}

	@media (max-width: breakpoint.$xs),
		(max-width: breakpoint.$sm),
		(max-width: breakpoint.$md),
		(max-width: breakpoint.$md-2) {
		// left-section of the page
		.left-section {
			display: none;
		}

		// right-section of the page
		.right-section {
			width: 100% !important;
			height: 100vh;

			.nexcaliber-logo {
				width: 60%;
			}

			h3 {
				@include auth.rightSectionMessage;

				span {
					font-weight: 400;
				}
			}

			.subheading {
				font-size: 15px;
			}

			.form-container {
				@include auth.authFormLayout;

				.form-field-input {
					margin-top: 20px !important;
					border-radius: 5px;
					width: 85%;
				}

				.confirm-password-field {
					margin-top: 30px !important;
				}
			}

			.sign-up-link-container {
				@include auth.signInHelpLinkContainer;
				margin-top: 30px !important;

				.link-container {
					.sign-up-link {
						color: map-get(map-get($colors, "auth"), "secondary-1");
						@include typography.supportiveFooterLinks;
					}
					.sign-up-link:hover {
						text-decoration: underline !important;
					}
				}
			}

			.sign-in-container {
				@include auth.signInHelpLinkContainer;
				margin-top: 40px !important;

				.link-container {
					.sign-in-link {
						color: map-get(map-get($colors, "auth"), "secondary-1");
						@include typography.supportiveFooterLinks;
					}
					.sign-in-link:hover {
						text-decoration: underline !important;
					}
				}
			}

			.reset-password-button-container {
				display: flex;
				justify-content: center;
				margin-top: 40px;
				width: 100%;

				.reset-password-button {
					background-color: #8c68e1 !important;
					color: "#ffff";
					padding: 10px;
					width: 240px !important;
					border-radius: 50px !important;

					.button-label-with-icon {
						color: white;
					}

					.button-icon {
						color: #ffff;
						margin-left: 15px;
					}
				}
			}

			.copyright-text-container {
				@include typography.copywriteText;
			}
		}
	}

	@media (max-width: breakpoint.$ipad_air),
		(max-width: breakpoint.$ipad_air_next_gen),
		(max-width: breakpoint.$ipad_mini),
		(max-width: breakpoint.$ipad_mini_next_gen) {
		.right-section {
			.nexcaliber-logo {
				width: 250px;
			}
		}
	}
}
