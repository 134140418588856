@use "../base/font-face" as fonts;
@import "../partials/colors";

@mixin mainPageLayout {
	width: 100%;
	display: flex;
	flex-direction: row;
}

@mixin leftPageSection {
	height: 100vh;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: map-get(map-get($colors, "auth"), "primary-1");
}

@mixin rightSection {
	width: 50%;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: white;
	//background-color: map-get(map-get($colors, "auth"), "base-1");
}

@mixin leftTaglineLayout {
	margin: 10px;
	// position: relative;
	// top: 120px;
	// right: 210px;
}

@mixin rightSectionMessage {
	font-family: fonts.$auth-font-primary !important;
	margin-top: 40px;
	font-size: 35px;
	font-weight: 800;
	opacity: 85%;
	color: #344e67 !important;
	span {
		font-weight: bold !important;
		opacity: 50%;
		color: 344D67;
	}
}

@mixin calloutMessage {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: map-get(map-get($colors, "auth"), "primary-2");
	border-radius: 0.7rem;
	padding: 1.2rem;
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

@mixin calloutMessageContainer {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
}

@mixin authFormLayout {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

@mixin signUpHelpLinkContainer {
	margin-top: 40px;
	font-family: fonts.$auth-font-primary !important;
}

@mixin signInHelpLinkContainer {
	margin-top: 40px;
	font-family: fonts.$auth-font-primary !important;
}

@mixin supportiveFooterLinksLayout {
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-family: fonts.$auth-font-primary !important;
}
