@tailwind base;
@tailwind components;
@tailwind utilities;
/* In your index.css */
@import "leaflet/dist/leaflet.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");
html,
body {
  /* margin: 0; */
  font-family: "Quicksand", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: #4e4e4e !important;
  /* background-color: #eeeeee; */
  background-color: #f7f9f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .glb-tab-panel {
	margin-bottom: 1rem !important;
	font-family: Arial, Helvetica, sans-serif !important;
} */

.pay-per-lead-text {
  font-family: "Arial", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff; /* Changed text color for better contrast */
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(45deg, #b1a0da, #a084e5, #8b67e1);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.glb-tab-panel .MuiTab-textColorPrimary.Mui-selected {
  /* color: #28a745 !important; */
  color: #7c00fe !important;
  /* background-color: rgba(40, 167, 69, 0.12549019607843137) !important; */
  background-color: #f2e7fa !important;
  border-radius: 5px !important;
  font-size: 16px !important;
}
.tab-label {
  font-size: 16px !important;
}
.superadmin-sidebar {
  margin: auto !important;
}

/* 
.green-contained-button {
	background-color: #d7ffe4 !important;
	border-width: 0.8px !important;
	font-weight: 500 !important;
	border-color: #009933 !important;
	border-radius: 20px !important;
	color: #009933 !important;
	text-transform: capitalize !important;
	font-family: "Raleway", sans-serif !important;
} */
.purple-outlined-button,
.small-purple-outlined-button {
  padding: 2px 8px !important;
  font-size: small;
  font-weight: 500 !important;
  background-color: #f2e7fa !important;
  color: #7c00fe !important;
  font-weight: 600 !important;
  border: 1px solid #7c00fe !important;
  text-transform: capitalize !important;
  font-family: "Raleway", sans-serif !important;
}
.small-purple-outlined-button {
  padding: 0px 4px !important;
  font-size: 13px !important;
}
.btn {
  background-color: #2283a4 !important;
  text-transform: capitalize !important;
  font-family: "Mulish", sans-serif !important;
  color: white !important;
}
.btn:hover {
  background-color: #08566a !important;
}
.custom-pagination .MuiPaginationItem-root {
  background-color: rgb(163, 163, 163) !important; /* Default background */
  color: white !important; /* Default text */
  border: none !important;
}

.custom-pagination .MuiPaginationItem-root.Mui-selected {
  background-color: rgb(37, 133, 243) !important; /* Selected page background */
  color: white !important; /* Selected page text */
  font-weight: bold !important;
}

/* body {
    font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  } */

.download-btn {
  background-color: #244559 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: "Mulish", sans-serif !important;
  width: auto !important;
}
.add-lead-btn {
  background-color: #244559 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: "Mulish", sans-serif !important;
}
.download-btn:hover {
  background-color: #2a5c68 !important;
}
.ag-header-cell-text {
  font-family: "Mulish", sans-serif !important;
  font-size: 16px !important;
  color: #434343 !important;
}
.ag-theme-alpine .ag-row {
  font-family: "Mulish", sans-serif !important;
  font-size: 14px !important;
  color: rgb(61, 62, 61) !important;
}
.download-btn {
  background-color: #244559 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: "Mulish", sans-serif !important;
}
.white-btn {
  background-color: #e6e6ff !important;
  color: rgb(2, 0, 55) !important;
  text-transform: capitalize !important;
  font-family: "Mulish", sans-serif !important;
}
.add-lead-btn {
  background-color: #244559 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: "Mulish", sans-serif !important;
}
.download-btn:hover {
  background-color: #2a5c68 !important;
}
.ag-header-cell-text {
  font-family: "Mulish", sans-serif !important;
  font-size: 16px !important;
  color: #434343 !important;
}
.ag-theme-alpine .ag-row {
  font-family: "Mulish", sans-serif !important;
  font-size: 14px !important;
  color: rgb(61, 62, 61) !important;
}

/* calendar styles */

.rbc-header {
  background-color: #119e40 !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
  font-size: 15px !important;
  font-family: "Mulish", sans-serif !important;
}
.rbc-allday-cell {
  display: none !important;
  font-family: "Mulish", sans-serif !important;
}
.rbc-rows {
  height: 500px !important;
  font-family: "Mulish", sans-serif !important;
}
.rbc-time-header-cell .rbc-today {
  background-color: rgb(225, 238, 243) !important;
  color: black !important;
  font-family: "Mulish", sans-serif !important;
}
.rbc-time-slot {
  min-height: 30px !important;
  /* min-width: 80px !important; */
  color: #009933 !important;
  font-size: 13px !important;
  font-family: "Mulish", sans-serif !important;
}

.rbc-label {
  font-size: 12px !important;
  font-family: "Mulish", sans-serif !important;
}
/* .rbc-events-container{
    width: 300px !important;
    height: 80px !important;
    background-color: #007427;
  } */

.dark-green-button {
  background-color: #007427 !important;
  color: white !important;
  text-transform: capitalize !important;
}

.dark-button {
  background-color: #383838 !important;
  color: white !important;
  text-transform: capitalize !important;
}
.ag-theme-alpine {
  --ag-alpine-active-color: #589bb1 !important;
  --ag-selected-row-background-color: #dbe9ed !important;
  --ag-row-hover-color: rgb(246, 249, 249) !important;
}

/* Style from Different Portal */
/* This Style is to turn-off the Default Visibility Icon of Password of the Browser */
::-ms-reveal {
  display: none;
}

button {
  text-transform: none !important;
}

.button-green {
  margin: 0px 10px !important;
  text-transform: none !important;
  background-color: #44b700 !important;
  color: #ffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.button:hover {
  filter: brightness(88%);
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-green:hover {
  filter: brightness(88%);
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.button-blue {
  /* width: 180px; */
  text-transform: none !important;
  background-color: rgb(12, 109, 227) !important;
  color: #ffff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif !important;
}

.button-violet {
  text-transform: none !important;
  background-color: #8b67e1 !important;
  color: #ffff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif !important;
  padding: 12px !important;
}

.button-violet:hover {
  filter: brightness(88%);
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-bright-violet {
  margin: 0px 10px !important;
  text-transform: none !important;
  background-color: #7c00fe !important;
  color: #ffff !important;
}

.button-bright-violet:hover {
  filter: brightness(88%);
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-dark-blue {
  margin: 0px 20px !important;
  text-transform: none !important;
  background-color: #142938 !important;
  color: #ffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
}

.button-dark-green:hover {
  filter: brightness(88%);
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-black {
  margin: 0px 10px !important;
  text-transform: none !important;
  background-color: #3a4652 !important;
  color: #ffff !important;
}

.button-black:hover {
  filter: brightness(88%);
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Button Label With Icon */
.button-label-with-icon {
  margin-right: 10px !important;
  font-size: 0.9rem !important;
  font-weight: bold !important;
  letter-spacing: 0.1em !important;
  color: #ffff;
}

/* Icon Style */
.button-icon {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  display: flex !important;
  color: #ffff;
}
.violet-outlined {
  outline: 1px solid #7c00fe !important;
  color: #7c00fe !important;
}
.sidebar-toggle-btn {
  width: 25px;
  height: 25px;
  background-color: #7c00fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.switch {
  color: #7c00fe !important;
}
.logo-content {
  background-color: rgb(234, 232, 232);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 5px 1px rgba(255, 254, 254, 0.454);
}
.logo-content img {
  width: 60%;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.cards-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.revenue-card {
  width: 250px;
  padding: 20px;
  height: 100px;

  justify-content: center;
  align-items: center;
  border-left: 5px solid #9a3ddc;
  background: linear-gradient(60deg, white, #e1ccec);
  transition: box-shadow 0.3s ease;
  outline: none;
}
.revenue-card:hover {
  box-shadow: 0 4px 10px 4px rgba(244, 139, 248, 0.395);
}
.leads-card {
  width: 250px;
  border-left: 5px solid #0bc34f;
  padding: 20px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(60deg, white, #aff0b3);
  transition: box-shadow 0.3s ease;
  outline: none;
}
.leads-card:hover {
  box-shadow: 0 4px 10px 4px rgba(80, 251, 120, 0.395);
}
.customer-card {
  width: 250px;
  border-left: 5px solid #0f9bbe;
  padding: 20px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(60deg, white, #adcafd);
  transition: box-shadow 0.3s ease;
  outline: none;
}
.customer-card:hover {
  box-shadow: 0 4px 10px 4px rgba(77, 74, 244, 0.395);
}
.request-card {
  width: 250px;
  border-left: 5px solid #f98f1e;
  padding: 20px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(60deg, white, #f9c975);
  transition: box-shadow 0.3s ease;
  outline: none;
}
.request-card:hover {
  box-shadow: 0 4px 10px 4px rgba(251, 146, 48, 0.395);
}
.button-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
.form-container {
  width: 100% !important;
}
.flex-input-field {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 20px !important;
  margin-bottom: 5px !important;
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.inputbox {
  width: 500px !important;
  font-size: 16px !important;
}
.flex-input-field label {
  font-size: 18px !important;
}
.button-container {
  display: flex !important;
  justify-content: right !important;
  align-items: right !important;
  margin-top: 20px !important;
}
.edit-btn-container {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: right;
  margin-bottom: 20px;
}
.add-btn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: right;
  margin-bottom: 20px;
}
.switch-button-container {
  width: 500px;
}
.card-container {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
.card-container .card {
  width: 230px;

  border-radius: 10px;
  background-color: #f2f2f2;
  /* border: 1px solid #cccccc; */
}
.card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.brandlogo-container {
  width: 200px;
  height: 40px;
  /* background-color: rgb(64, 163, 243); */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  background-color: rgb(4, 61, 74);
  border: 1px solid rgba(48, 255, 97, 0.744);
  box-shadow: 0 1px 2px 1px rgba(172, 255, 47, 0.306);
  /* box-shadow: 0 2px 1px 3px rgba(48, 255, 97, 0.744); */
}
.brandlogo-container img {
  max-width: 70%;
}
.label-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.revenue-section {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 40px;
  padding: 10px;
}
.revenue-label {
  font-size: 30px;
  font-weight: 400;
}
.amount {
  font-size: 35px;
  font-weight: 500;
  color: #8b67e1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label-value-pair {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 10px;
  grid-gap: 2.8rem;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.label-value-pair p {
  margin: 0;
}
.label-icon {
  width: 200px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 8px;
}
.label-with-icon label {
  font-size: 16px;
  font-weight: 600;
  color: #383838;
}
.value-container {
  display: block;
}
.value {
  font-size: 20px;
  font-weight: 600;
  color: #636363;
}
.value-label {
  font-size: 13px;
  color: #7c00fe;
}
.paid-transaction {
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #05af46;
  margin: 4px;
  color: white;
  font-weight: bold;
  font-size: 15px;
}
.transaction-type-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.transaction-type {
  width: auto;
  height: 20px;
  padding: 5px 15px;
  margin: 5px;
  border-radius: 50px;
  background-color: rgb(212, 230, 251);
  color: rgb(12, 109, 227);
  font-weight: bold;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leads-select-outline {
  width: 250px;
}
#demo-simple-select-label {
  color: rgb(90, 90, 90) !important;
  font-weight: 400 !important;
}
.outline-select-with-label {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
}
.outline-select-with-label label {
  font-size: 16px;
  font-weight: 700;
  color: #676666;
}
.leads-label-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.selected-leads-header {
  font-size: 23px;
  margin-top: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.results-for-selected-leads {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.leads-data-container-grid {
  margin-top: 2rem;
}
.text-value {
  font-size: 50px !important;
}

.change-card-btn {
  width: auto;
  text-transform: none !important;
  background-color: rgb(247, 247, 247) !important;
  color: rgb(219, 19, 19) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif !important;
}

.flex-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.button-blue:hover {
  background-color: rgb(8, 90, 191) !important;
}

.chip-label-flex {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}
.flex-headings {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}
.label-and-input-field {
  display: flex !important;
  flex-direction: column !important;
}

.cancel-button {
  background-color: #f3f3f3 !important;
  margin: 5px !important;
  color: #2084f0 !important;
  font-size: 16px !important;
}
.confirm-button {
  font-size: 16px !important;
  margin-right: 10px !important;
}
.no-card-details-text {
  width: 95%;
  padding: 15px;
  border-radius: 5px;
  background-color: rgb(244, 243, 243);
  display: flex;
  justify-content: center;
  align-items: center;
}

.indigo-button {
}

/* From Uiverse.io by Creatlydev */
.Btn {
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3b38f6;
  border: none;
  color: white;
  font-weight: 600;
  gap: 8px;
  cursor: pointer;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}

/* Add scaling animation */
.Btn:hover {
  transform: scale(1.1);
  box-shadow: 8px 8px 20px rgba(59, 56, 246, 0.3);
  background-color: #2f2de4; /* Slightly darker background on hover */
}

/* Add ripple effect using a pseudo-element */
.Btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: width 0.4s ease, height 0.4s ease, opacity 0.5s ease;
}

.Btn:hover::before {
  width: 300%;
  height: 300%;
  opacity: 0.3;
}

/* Icon animation (if applicable) */
.Btn svg {
  transition: transform 0.3s ease;
}

.Btn:hover svg {
  transform: rotate(360deg);
}

.svgIcon {
  width: 16px;
}

.svgIcon path {
  fill: white;
}

.no-wrap {
  white-space: nowrap;
}

.PlanBtn,
.PlanBtn2 {
  width: 140px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    #77530a,
    #ffd277,
    #77530a,
    #77530a,
    #ffd277,
    #77530a
  );
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
}

.PlanBtn2 {
  width: 140px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    #77530a,
    #ffd277,
    #77530a,
    #77530a,
    #ffd277,
    #77530a
  );
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
}

.PlanBtn::before {
  position: absolute;
  content: "SUBSCRIBE";
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
  background-size: 200%;
}
.PlanBtn2::before {
  position: absolute;
  content: "Upgrade Plan";
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
  background-size: 200%;
}

.PlanBtn:hover,
.PlanBtn2:hover {
  background-position: right;
  transition-duration: 1s;
}

.PlanBtn:hover::before,
.PlanBtn2:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.PlanBtn:active,
.PlanBtn2:active {
  transform: scale(0.95);
}

.map-box {
  flex: 6;
}

.leads-stats {
  background-color: white;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.filter-dropdown {
  font-size: 18px;
  color: #333;
  cursor: pointer;
}
/* Section Title */
.section-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
}
.leads-cards-wrapper {
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
}
/* Leads Cards */
.leads-cards {
  display: grid;
  gap: 20px;
}

/* From Uiverse.io by vinodjangid07 */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 32px;
  background-color: #006aff;
  color: white;
  gap: 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s;
}
.text {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 1px;
}
.svg {
  padding-top: 5px;
  height: 100%;
  width: fit-content;
}
.svg svg {
  width: 50px;
  height: 30px;
}

.button:hover .svg svg {
  animation: jello-vertical 0.9s both;
  transform-origin: left;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.lead-card {
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  font-size: 16px;
  color: #333;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.lead-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.lead-card {
  text-align: left;
  font-size: 16px;
}
/* Card Actions */
.card-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  font-size: 20px;
  color: #555;
}
.card-actions span:hover {
  color: #000;
}
.leads-cards-wrapper::-webkit-scrollbar {
  width: 8px;
}
.leads-cards-wrapper::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}
.leads-cards-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
.dialog-content {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
}
.dialog-content-subtitle {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.459);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(8px);
}

.overlay-message {
  color: rgba(0, 0, 0, 0.708);
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  z-index: 2000000;
}

.blurred {
  filter: blur(5px);
  pointer-events: none; /* Disable interactions */
}
.unblurred {
  pointer-events: none; /* Disable interactions */
}

.roboto-slab {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.outlined-box {
  width: 100% !important;
  height: auto !important;

  justify-content: center !important;
  align-items: center !important;
  display: block !important;
  border-radius: 10px !important;
  border: 0.5px solid gray !important;
}
.button-flex {
  justify-content: right !important;
  align-items: right !important;
  display: flex !important;
  gap: 6px !important;
}
